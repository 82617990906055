import { getUrlParameter } from 'Shared/Common/Helpers';
import { FilterMessage } from '../filterMessages';
import {
  resetRangeFilters,
  withMutateUrl,
  parseDataToFilter,
} from './filterReducerHelperFunctions';
import { FilterState } from './filterState';

export const checkNever = (_: never) => {};

export const filterReducer = (
  state: FilterState,
  message: FilterMessage
): FilterState => {
  switch (message.type) {
    case 'onClear':
      return withMutateUrl({
        ...state,
        multiSelectFilters: new Map(),
        rangeFilters: resetRangeFilters(state.rangeFilters, state.data?.facets),
        loadedItems: getUrlParameter('items'),
      });
    case 'onChangeCategory':
      return withMutateUrl({
        ...state,
        selectedModel: '',
        multiSelectFilters: new Map(),
        conditionFilter: new Set(),
        rangeFilters: new Map(),
        sorterFilter: state.initSorterFilter,
        loadedItems: '0',
      });
    case 'setMultiSelectFilter': {
      const { checked, key, value } = message;
      const newFilter = new Map(state.multiSelectFilters);
      const set = newFilter.get(key) || new Set();

      checked ? set.add(value) : set.delete(value);
      newFilter.set(key, set);

      return withMutateUrl({
        ...state,
        multiSelectFilters: newFilter,
      });
    }
    case 'setRangeFilter': {
      const { key, currentMin, currentMax, min, max } = message;
      const newFilter = new Map(state.rangeFilters);
      newFilter.set(key, { currentMin, currentMax, min, max });
      return withMutateUrl({ ...state, rangeFilters: newFilter });
    }
    case 'setSorterFilter':
      return withMutateUrl({ ...state, sorterFilter: message.value });
    case 'setMultiSelectFilters':
      return withMutateUrl({
        ...state,
        multiSelectFilters: message.selectFilters,
      });
    case 'setQuery':
      return withMutateUrl({ ...state, query: message.value });
    case 'setSorterFilterByText':
      if (!state.data) return state;
      return withMutateUrl({
        ...state,
        sorterFilter:
          state.data.sorters.find(
            (element) => element.text === message.value
          ) || state.sorterFilter,
      });
    case 'setSelectedModel':
      return withMutateUrl({
        ...state,
        selectedModel: message.value,
      });
    case 'setFacets':
      return withMutateUrl(
        parseDataToFilter(state, {
          facets: message.facets,
          sorters: message.sorters,
        })
      );
    case 'setLoadedItems':
      return withMutateUrl({
        ...state,
        loadedItems: message.value,
      });
    default:
      /* Neat ts trick, the row below will error if you forgot a message type,
      hover over the error to see the missing message
      */
      checkNever(message);
      return state;
  }
};
