import { useEffect } from 'react';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import useMedia from './useMedia';

function usePreventBackgroundScroll() {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);

  useEffect(() => {
    const documentWidth = document.documentElement.clientWidth;
    const windowWidth = window.innerWidth;
    const scrollBarWidth = windowWidth - documentWidth;

    document.body.classList.add('hide-scrollbar');
    document.body.style.paddingRight = `${scrollBarWidth}px`;
    document.body.style.overflowY = 'hidden';

    //For ios
    let scrollPosition = 0;
    if (isMobile) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      scrollPosition = window.pageYOffset;
      document.body.style.top = scrollPosition + 'px';
    }

    return () => {
      document.body.classList.remove('hide-scrollbar');
      //For ios
      if (isMobile) {
        document.body.style.position = 'unset';
        window.scrollTo(0, scrollPosition);
        document.body.style.top = 'unset';
        document.body.style.width = 'auto';
      }
      document.body.style.paddingRight = '0px';
      document.body.style.overflowY = 'initial';
      document.body.style.overflowX = 'hidden';
    };
  }, [isMobile]);
}

export default usePreventBackgroundScroll;
