import KexRange from '../../../Models/Search/KexRange.interface';
import KexTerm from '../../../Models/Search/KexTerm.interface';
import { FilterState } from '../../../Organisms/FilterComponent/FilterReducer/filterState';

const FILTER = 'filter::';
const CONDITION_FILTER = `${FILTER}ProductCondition`;
const ORDER = 'order';

export const getFiltersFromUrl = (
  paramsArray: [string, string][],
  data: NonNullable<FilterState['data']>
) => {
  const params = new Map(paramsArray);
  const ranges = (data.facets || [])
    .filter((f) => !!f?.ranges?.length)
    .map((f): [string, KexRange] => [f.name, f.ranges[0]]);

  const multiSelect = (data.facets || [])
    .filter((f) => !!f.terms)
    .map((f): [string, KexTerm[]] => [f.name, f.terms]);

  const outRange = new Map(
    ranges.map(([key, range]) => {
      const result = params.get(FILTER + key);
      const numbers = result?.split('..');

      if (numbers?.length === 2) {
        return [
          key,
          {
            min: range.min.toString(),
            max: range.max.toString(),
            currentMin: numbers[0],
            currentMax: numbers[1],
          },
        ];
      } else {
        return [
          key,
          {
            min: range.min.toString(),
            max: range.max.toString(),
            currentMin: range.min.toString(),
            currentMax: range.max.toString(),
          },
        ];
      }
    })
  );

  const outMulti = new Map(
    multiSelect.map(([key]) => {
      const values = (params.get(FILTER + key) || '')
        .split(';')
        .filter((s) => !!s);
      return [key, new Set(values)];
    })
  );

  const conditionFilter = new Set<'New' | 'Used'>();

  params
    .get(CONDITION_FILTER)
    ?.split(',')
    .filter((s): s is 'New' | 'Used' => s === 'New' || s === 'Used')
    .forEach((s) => conditionFilter.add(s));

  const sortOrderModel =
    data.sorters.find(
      (s) => s.value === Number.parseInt(params.get(ORDER) || '')
    ) || data.sorters[0];

  return { outRange, outMulti, conditionFilter, sortOrderModel };
};
