import CloseButton from 'Atoms/Buttons/CloseButton';
import React, { useRef } from 'react';
import useMountTransition from 'Shared/Hooks/useMountTransition';
import { styled } from 'stitches.config';
import { timings, animation } from 'Theme/Settings/animation';
import ReactDOM from 'react-dom';
import ModalOverlay from 'Atoms/Overlay/ModalOverlay';
import { canUseDOM } from 'Shared/Common/Helpers';

type PropType = {
  children: React.ReactNode;
  showModal: boolean;
  toggle?: () => void;
  hideCloseButton?: boolean;
  overlay?: boolean;
  noPadding?: boolean;
  showScrollbar?: boolean;
};

function Modal({
  showModal,
  children,
  toggle,
  noPadding = false,
  hideCloseButton,
  showScrollbar = true,
}: PropType) {
  const modalRef = useRef<HTMLDivElement>(null);
  const hasTransitionedIn = useMountTransition(showModal, 250);

  if (canUseDOM()) {
    const portalElement = document.querySelector<Element>('#root-content');

    return ReactDOM.createPortal(
      <>
        <ModalContainer isVisible={showModal}>
          <ModalContent
            ref={modalRef}
            isVisible={showModal}
            noPadding={noPadding}
            showScrollbar={showScrollbar}
          >
            {toggle && !hideCloseButton && (
              <CloseWrapper>
                <CloseButton
                  size="l"
                  css={{ marginLeft: 'auto' }}
                  onClick={toggle}
                  bgColor="primary"
                />
              </CloseWrapper>
            )}
            {children}
          </ModalContent>
        </ModalContainer>
        {(hasTransitionedIn || showModal) && (
          <ModalOverlay
            isVisible={hasTransitionedIn && showModal}
            clickEvent={toggle}
          />
        )}
      </>,
      portalElement as Element
    );
  } else {
    return <></>;
  }
}

const CloseWrapper = styled('div', {
  display: 'flex',
  position: 'absolute',
  zIndex: 9,
  top: 16,
  right: 16,
  '@mediaMinLarge': {
    top: 24,
    right: 24,
  },
});

const ModalContainer = styled('div', {
  position: 'fixed',
  visibility: 'hidden',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  top: 0,
  right: 0,
  h: '100%',
  w: '100%',
  opacity: 1,
  zIndex: '$zIndices$Modal',
  transition: `all ${timings.oneHalf} ${animation.timingFn}`,
  variants: {
    isVisible: {
      true: {
        visibility: 'initial',
      },
    },
  },
});

const ModalContent = styled('div', {
  overflow: 'auto',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: 0,
  p: 4,
  opacity: 1,
  marginLeft: 'auto',
  backgroundColor: '$modalContentBackground',
  borderLeft: '1px solid $modalContentBorder',
  transition: `height ${timings.oneHalf} ${animation.timingFn}`,
  '@mediaMinLarge': {
    position: 'relative',
    transition: `all ${timings.oneHalf} ${animation.timingFn}`,
    height: '100%',
    width: 0,
    p: 6,
    transform: 'translateX(300%)',
  },
  variants: {
    isVisible: {
      true: {
        height: '97%',
        '@mediaMinLarge': {
          width: '480px',
          height: '100%',
          transform: 'translateX(0)',
        },
      },
    },
    noPadding: {
      true: {
        p: 0,
      },
    },
    showScrollbar: {
      false: {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
      },
    },
  },
});

export default Modal;
