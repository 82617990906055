import usePreventBackgroundScroll from 'Shared/Hooks/usePreventBackgroundScroll';
import { styled } from 'stitches.config';

type PropTypes = {
  clickEvent?: () => void;
  isVisible: boolean;
  overlayColor?: 'primary' | 'secondary';
};

function ModalOverlay({
  clickEvent,
  isVisible,
  overlayColor = 'primary',
}: PropTypes) {
  usePreventBackgroundScroll();

  return (
    <>
      <ModalOverlayLeftPane
        onClick={clickEvent}
        isVisible={isVisible}
        overlayColor={overlayColor}
      />
      <ModalOverlayRightPane
        isVisible={isVisible}
        overlayColor={overlayColor}
      />
    </>
  );
}

export default ModalOverlay;

const ModalOverlayLeftPane = styled('div', {
  zIndex: '$Modal',
  transition: 'all 0.25s ease-in-out',
  visibility: 'hidden',
  position: 'fixed',
  height: '3vh',
  width: '120vw',
  t: 0,
  l: -15,
  '@mediaMinLarge': {
    h: '100vh',
    width: 'calc(100% - 496px)',
    t: 0,
    l: 0,
  },
  variants: {
    isVisible: {
      true: {
        visibility: 'initial',
      },
    },
    overlayColor: {
      primary: {
        backgroundColor: '$modalOverlayPrimary',
      },
      secondary: {
        backgroundColor: '$modalOverlaySecondary',
      },
    },
  },
});

const ModalOverlayRightPane = styled('div', {
  transition: 'all 0.25s ease-in-out',
  visibility: 'hidden',
  zIndex: '$ModalOverlay',
  position: 'fixed',
  height: '97vh',
  width: '120vw',
  right: 'unset',
  top: 'unset',
  left: 0,
  bottom: 0,
  '@mediaMinLarge': {
    h: '100vh',
    width: '496px',
    r: 0,
    t: 0,
    b: 'unset',
    l: 'unset',
  },
  variants: {
    isVisible: {
      true: {
        visibility: 'initial',
      },
    },
    overlayColor: {
      primary: {
        backgroundColor: '$modalOverlayPrimary',
      },
      secondary: {
        backgroundColor: '$modalOverlaySecondary',
      },
    },
  },
});
