import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import Fetcher from 'Shared/Common/Fetcher';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { canUseDOM, FILTER_URL_CONSTANTS } from 'Shared/Common/Helpers';
import FullSearchResult from '../../Models/Search/FullSearchResult.interface';
import SearchPhraseResult from '../../Models/Search/SearchPhraseResult.interface';
import KexFacet from '../../Models/Search/KexFacet.interface';
import { useFilterData } from 'Organisms/FilterComponent/FilterProvider';

let abortController: AbortController = new AbortController();

export enum SearchTypes {
  Products = 'getProduct',
  Content = 'getContent',
  Documents = 'getDocument',
  Empty = '',
}

const toParamString = (params: [string, string][]) =>
  params.map(([key, value]) => `${key}=${value}`).join('&');

const noResult = (result: FullSearchResult | undefined) => {
  if (result === undefined) {
    return false;
  }

  if (result.productSearchResult?.items?.length) {
    return false;
  }

  if (result.categorySearchResult?.items?.length) {
    return false;
  }

  if (result.documentSearchResult?.items?.length) {
    return false;
  }

  if (result.contentSearchResult?.items?.length) {
    return false;
  }

  return true;
};

const merge = (
  fullFacets?: KexFacet[],
  filteredFacets?: KexFacet[]
): typeof fullFacets => {
  if (!fullFacets) return undefined;

  const mp = new Map(filteredFacets?.map((k) => [k.name, k] as const));

  return fullFacets
    .map((facet) => {
      const facetFilter = mp.get(facet.name);

      const filtered = new Map(
        facetFilter?.terms?.map((term) => [term.term, term.count])
      );

      const terms = facet.terms?.map((term) => {
        const count = filtered.get(term.term) || 0;
        return { ...term, count };
      });

      if (!terms?.length && facet.terms?.length) {
        return undefined;
      }

      return { ...facet, terms };
    })
    .filter((f): f is KexFacet => !!f);
};

export async function QuickSearch(
  searchPage: string,
  query: string,
  languageRoute: string,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  const url = `${searchPage}QuickSearch?query=${query}&language=${languageRoute}`;
  try {
    const res = await fetch(url);
    setIsLoading(false);
    if (res.ok) {
      const data = await res.json();
      return data as SearchPhraseResult;
    }
  } catch {
    return undefined;
  }
}

const takeParameter: [string, string][] = [
  ['take', FILTER_URL_CONSTANTS.DEFAULT_ITEMS.toString()],
];

const getAll: [string, string][] = [
  ['getProduct', 'true'],
  ['getContent', 'true'],
  ['getDocument', 'true'],
];

export function useSearch(
  searchPage: string,
  filterParams: [string, string][]
) {
  const { pageCacheTime } = useAppSettingsData();
  const [fullResult, setFullResult] = useState<FullSearchResult>();
  const [loadingMore, setLoadingMore] = useState(false);
  const [state] = useFilterData();
  const [, query] = filterParams.find(
    ([key]) => key === FILTER_URL_CONSTANTS.SEARCH_QUERY
  ) || [FILTER_URL_CONSTANTS.SEARCH_QUERY, ''];

  const dynamicTakeParameter: [string, string][] = useMemo(() => {
    return [['take', state.loadedItems !== '0' ? state.loadedItems : '24']];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const url = `${searchPage}Search?`;

  const facetedResult = useSWR(
    query,
    async () => {
      if (!query) {
        return undefined;
      }
      try {
        return await FetchSearch(
          url +
            toParamString([
              ...takeParameter,
              ...getAll,
              [FILTER_URL_CONSTANTS.SEARCH_QUERY, query],
            ])
        );
      } catch {
        return undefined;
      }
    },
    {
      revalidateOnFocus: false,
      dedupingInterval: pageCacheTime,
    }
  );

  const result = useSWR(
    url + toParamString([...dynamicTakeParameter, ...getAll, ...filterParams]),
    async (url) => {
      if (!query) {
        return undefined;
      }
      try {
        return await FetchSearch(url);
      } catch {
        return undefined;
      }
    },
    {
      fallbackData: fullResult,
      revalidateOnFocus: false,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (result.data) setFullResult(result.data);
  }, [result.data]);

  const paginate = useCallback(
    async (searchType: SearchTypes) => {
      const type =
        searchType === SearchTypes.Products
          ? 'productSearchResult'
          : 'documentSearchResult';

      setLoadingMore(true);

      const skip = fullResult ? fullResult[type].items.length : 0;
      const getType =
        type === 'productSearchResult' ? 'getProduct' : 'getDocument';

      const moreUrl =
        url +
        toParamString([
          ...filterParams,
          ['skip', skip.toString()],
          ...takeParameter,
          [getType, 'true'],
        ]);

      try {
        const res = await FetchSearch(moreUrl);
        if (res) {
          setFullResult((r) =>
            !r
              ? res
              : {
                  ...res,
                  [type]: {
                    ...res[type],
                    items: [...r[type].items, ...res[type].items],
                  },
                }
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMore(false);
      }
    },
    [fullResult, url, filterParams]
  );

  const currentFacets = useMemo(
    () =>
      merge(
        facetedResult.data?.productSearchResult?.facets,
        result.data?.productSearchResult?.facets
      ),
    [
      result.data?.productSearchResult?.facets,
      facetedResult.data?.productSearchResult?.facets,
    ]
  );

  return useMemo(
    () => ({
      paginate,
      isLoading: result.isValidating || facetedResult.isValidating,
      loadingMore,
      facets: currentFacets,
      sorters: facetedResult.data?.productSearchResult.sorters,
      noResult: noResult(facetedResult.data),
      result: fullResult,
      error: result.error,
    }),
    [
      result.isValidating,
      facetedResult.isValidating,
      currentFacets,
      result.error,
      fullResult,
      loadingMore,
      facetedResult.data,
      paginate,
    ]
  );
}

export function useQueryCategoryItems(
  apiUrl: string,
  filterParams: [string, string][],
  pagePath: string
) {
  if (!pagePath.endsWith('/')) {
    pagePath = pagePath + '/';
  }

  const url = canUseDOM() ? `${pagePath}${apiUrl}?` : '';
  const [state] = useFilterData();
  const { pageCacheTime } = useAppSettingsData();
  const [fullResult, setFullResult] = useState<FullSearchResult>();
  const [loadingMore, setLoadingMore] = useState(false);
  const dynamicTakeParameter: [string, string][] = useMemo(() => {
    return [['take', state.loadedItems !== '0' ? state.loadedItems : '24']];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const facetedResult = useSWR(
    url + toParamString(takeParameter),
    async (url) => {
      try {
        return await FetchSearch(url);
      } catch {
        return undefined;
      }
    },
    {
      fallbackData: fullResult,
      revalidateOnFocus: false,
      dedupingInterval: pageCacheTime,
    }
  );

  const result = useSWR(
    url + toParamString([...dynamicTakeParameter, ...filterParams]),
    async () => {
      try {
        return await FetchSearch(
          url + toParamString([...dynamicTakeParameter, ...filterParams])
        );
      } catch {
        return undefined;
      }
    },
    {
      fallbackData: fullResult,
      revalidateOnFocus: false,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (result.data) setFullResult(result.data);
  }, [result.data]);

  const paginate = useCallback(async () => {
    setLoadingMore(true);

    const skip = fullResult ? fullResult.productSearchResult.items.length : 0;

    const moreUrl =
      url +
      toParamString([
        ...takeParameter,
        ...filterParams,
        ['skip', skip.toString()],
      ]);

    try {
      const res = await FetchSearch(moreUrl);
      if (res) {
        setFullResult((r) =>
          !r
            ? res
            : {
                ...res,
                productSearchResult: {
                  ...res.productSearchResult,
                  items: [
                    ...r.productSearchResult.items,
                    ...res.productSearchResult.items,
                  ],
                },
              }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMore(false);
    }
  }, [fullResult, url, filterParams]);

  const currentFacets = useMemo(
    () =>
      merge(
        facetedResult.data?.productSearchResult?.facets,
        result.data?.productSearchResult?.facets
      ),
    [
      result.data?.productSearchResult?.facets,
      facetedResult.data?.productSearchResult?.facets,
    ]
  );

  return useMemo(
    () => ({
      paginate,
      isLoading: result.isValidating || facetedResult.isValidating,
      loadingMore,
      facets: currentFacets,
      sorters: facetedResult.data?.productSearchResult?.sorters,
      noResult: noResult(facetedResult.data),
      result: fullResult,
      error: result.error,
    }),
    [
      paginate,
      result.isValidating,
      facetedResult.isValidating,
      facetedResult.data,
      loadingMore,
      currentFacets,
      fullResult,
      result.error,
    ]
  );
}

function removeEmptyRanges(item: KexFacet) {
  if (!item.ranges?.length) return true;
  const range = item.ranges[0];
  if (Number.isNaN(range.min)) return true;
  return range.max > range.min;
}

function FetchSearch(url: string) {
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<FullSearchResult, FullSearchResult>(
    url,
    signal,
    (data, resolve) => {
      if (data?.productSearchResult?.facets) {
        data.productSearchResult.facets =
          data.productSearchResult.facets.filter(removeEmptyRanges);
      }

      resolve(data);
    },
    false
  );
}
