import KexFacet from '../../../Models/Search/KexFacet.interface';
import SorterModel from '../../../Models/Search/SorterModel.interface';
import { getFiltersFromUrl } from '../../../Pages/SearchPage/FilterComponent/UrlHandler';
import {
  FILTER_URL_CONSTANTS,
  getUrlParameter,
  setUrlParameter,
  setUrlParameters,
} from '../../../Shared/Common/Helpers';
import { FilterState, RangeType } from './filterState';

export function toggle<T>(oldSet: Set<T>, key: T): Set<T> {
  const newSet = new Set(oldSet);
  newSet.has(key) ? newSet.delete(key) : newSet.add(key);
  return newSet;
}

export const resetRangeFilters = (
  rangeFilters: Map<string, RangeType>,
  facets?: KexFacet[]
): typeof rangeFilters => {
  if (!facets) {
    return new Map();
  }

  const newRange = new Map(rangeFilters);

  Array.from(rangeFilters)
    .map(
      ([key]) => [key, facets.find((element) => element.name === key)] as const
    )
    .filter((entry): entry is [string, KexFacet] => !!entry[1])
    .forEach(
      ([
        key,
        {
          ranges: [{ min, max }],
        },
      ]) => {
        newRange.set(key, {
          min: min.toString(),
          max: max.toString(),
          currentMin: min.toString(),
          currentMax: max.toString(),
        });
      }
    );

  return newRange;
};

export const withMutateUrl = (state: FilterState): typeof state => {
  if (!state.data && !state.query) {
    if (state.query)
      setUrlParameter(FILTER_URL_CONSTANTS.SEARCH_QUERY, state.query);

    if (state.selectedModel) {
      setUrlParameter(
        FILTER_URL_CONSTANTS.SELECTED_MODEL_FILTER,

        state.selectedModel
      );
    }
    if (state.loadedItems && state.loadedItems !== '0') {
      setUrlParameter('items', state.loadedItems.toString());
    }
    return state;
  }

  const prs: [string, string][] = [
    [FILTER_URL_CONSTANTS.SELECTED_MODEL_FILTER, state.selectedModel],
    [FILTER_URL_CONSTANTS.SEARCH_QUERY, state.query],
    ['tab', getUrlParameter('tab')],
  ].filter((s): s is [string, string] => !!s[1]);

  if (state.sorterFilter.value !== 0) {
    prs.push([FILTER_URL_CONSTANTS.ORDER, state.sorterFilter.value.toString()]);
  }

  Array.from(state.multiSelectFilters)
    .filter(([, values]) => values.size > 0)
    .forEach(([key, values]) =>
      prs.push([
        FILTER_URL_CONSTANTS.FILTER + key,
        Array.from(values).join(';'),
      ])
    );

  Array.from(state.rangeFilters)
    .filter(
      ([_, values]) =>
        !!values &&
        (values.currentMax !== values.max || values.currentMin !== values.min)
    )
    .forEach(([key, values]) =>
      prs.push([
        `${FILTER_URL_CONSTANTS.FILTER}${key}`,
        `${values.currentMin}..${values.currentMax}`,
      ])
    );

  if (state.conditionFilter.size > 0) {
    prs.push([
      FILTER_URL_CONSTANTS.CONDITION_FILTER,
      Array.from(state.conditionFilter).join(','),
    ]);
  }

  setUrlParameters(prs);
  if (state.loadedItems && state.loadedItems !== '0') {
    setUrlParameter('items', state.loadedItems.toString());
  }
  return { ...state, queryParams: prs };
};

export const parseDataToFilter = (
  state: FilterState,
  data: { facets: KexFacet[]; sorters: SorterModel[] }
): typeof state => {
  const { outRange, outMulti, conditionFilter, sortOrderModel } =
    getFiltersFromUrl(state.queryParams, data);

  return {
    ...state,
    rangeFilters: outRange,
    multiSelectFilters: outMulti,
    conditionFilter,
    sorterFilter: sortOrderModel || state.sorterFilter,
    data,
  };
};

export const toQueryParams = (queryString: string) => {
  return Array.from(new URLSearchParams(queryString));
};
