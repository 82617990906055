import React, { useMemo } from 'react';
import { ReactNode, useEffect, useReducer } from 'react';
import { getSelectedModel, getUrlParameter } from '../../Shared/Common/Helpers';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';

import { useUserStateData } from '../../Shared/Providers/UserContextProvider';
import { FilterMessage } from './filterMessages';
import { filterReducer } from './FilterReducer/filterReducer';
import { toQueryParams } from './FilterReducer/filterReducerHelperFunctions';
import { FilterState } from './FilterReducer/filterState';

type Props = {
  children: ReactNode;
  urlSearchString: string;
  noQuery?: true;
};

type FilterContextType = [FilterState, React.Dispatch<FilterMessage>];

export const FilterContext = React.createContext(
  [] as unknown as FilterContextType
);

export const useFilterData = () => React.useContext(FilterContext);

export const FilterProvider = ({
  children,
  urlSearchString,
  noQuery,
}: Props) => {
  const {
    account: { query: queryFromGlobal },
    accountDispatch,
  } = useUserStateData();

  const {
    searchLabels: { sortOrderLatest },
  } = useTranslationData();

  const INIT: FilterState = {
    data: null,
    multiSelectFilters: new Map(),
    rangeFilters: new Map(),
    conditionFilter: new Set(),
    initSorterFilter: {
      selected: true,
      text: sortOrderLatest,
      value: 0,
    },
    sorterFilter: {
      selected: true,
      text: sortOrderLatest,
      value: 0,
    },
    queryParams: toQueryParams(urlSearchString),
    query: noQuery ? '' : queryFromGlobal,
    selectedModel: getSelectedModel(),
    loadedItems: getUrlParameter('items') || '0',
  };

  const [filterState, dispatch] = useReducer(filterReducer, INIT);

  // If local filter state changes, change global
  useEffect(() => {
    if (!noQuery) {
      accountDispatch({
        type: 'setQuery',
        query: filterState.query,
      });
    }
  }, [filterState.query, accountDispatch, noQuery]);

  // If global filter state changes, change local
  useEffect(() => {
    if (!noQuery) {
      dispatch({
        type: 'setQuery',
        value: queryFromGlobal,
      });
    }
  }, [queryFromGlobal, dispatch, noQuery]);

  const value = useMemo(
    (): FilterContextType => [filterState, dispatch],
    [filterState, dispatch]
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};
